import { IApplicationAreaList, IMenuLink } from '@layout/models/layout-model';

export const HELP_TOUR_EVENT_NAME = 'helptourevent';

export const endomainArray = ['www.insights-eu-preprod.numantra.us',
    'eu.insights.bayer.com',
    'www.insights-eu-dev.numantra.us',
    'www.insights-eu-uat.numantra.us',
    'eu.test.insights.bayer.com',
    'www.insights-eu.numantra.us'];

export const footerRoutes = {
    tools_privacy: '/resources/privacy',
    tools_contactus: '/resources/contactus',
}

export const MAIN_MENU = {
    cdm_metrics: 'cdm-metrics',
    report: 'report',
    resources: 'resources'
}

export const OTHER_APPLICATION_AREAS: IApplicationAreaList[] = [
    {
        id: 'CT',
        label: 'translation.SelectionPanel.report_ct'
    },
    {
        id: 'CT-M',
        label: 'translation.SelectionPanel.report_ctmp'
    },
    {
        id: 'MR',
        label: 'translation.SelectionPanel.report_mr'
    }
];

export const CONTRASTDASHBOARDMENU: IMenuLink[] = [
  {
    name: 'translation.ContrastDashboard.header',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: '/cdm-metrics/my-dashboard',
  },
  {
    name: 'translation.ContrastDashboard.labelRepeatInjections',
    imagePath: '../../../../assets/images/RepeatInjectionW.svg',
    activeImagePath: '../../../../assets/images/RepeatInjectionY.svg',
    url: '/cdm-metrics/repeat-injections',
  },
  {
    name: 'translation.ContrastDashboard.labelIVIssues',
    imagePath: '../../../../assets/images/IVIssuesW.svg',
    activeImagePath: '../../../../assets/images/IVIssuesY.svg',
    url: 'cdm-metrics/iv-issues',
  },
  {
    name: 'translation.ContrastDashboard.labelLostTime',
    imagePath: '../../../../assets/images/LostTimeW.svg',
    activeImagePath: '../../../../assets/images/LostTimeY.svg',
    url: 'cdm-metrics/lost-time',
  },
  {
    name: 'translation.ContrastDashboard.labelAtypicalTechAbort',
    imagePath: '../../../../assets/images/AtypicalTechnologistW.svg',
    activeImagePath: '../../../../assets/images/AtypicalTechnologistY.svg',
    url: 'cdm-metrics/tech-abort',
  },
  {
    name: 'translation.ContrastDashboard.labelAtypicalFRL',
    imagePath: '../../../../assets/images/AtypicalFlowrateW.svg',
    activeImagePath: '../../../../assets/images/AtypicalFlowrateY.svg',
    url: 'cdm-metrics/flow-rate-limited',
  },
  {
    name: 'translation.ContrastDashboard.labelSalineTestInject',
    imagePath: '../../../../assets/images/SalineTestInjectW.svg',
    activeImagePath: '../../../../assets/images/SalineTestInjectY.svg',
    url: 'cdm-metrics/saline-test-inject',
  },
  {
    name: 'translation.ContrastDashboard.labelContrastWaste',
    imagePath: '../../../../assets/images/ContrastwasteW.svg',
    activeImagePath: '../../../../assets/images/ContrastwasteY.svg',
    url: 'cdm-metrics/contrast-waste',
  },
  {
    name: 'translation.ContrastDashboard.labelDataCompleteness',
    imagePath: '../../../../assets/images/DataCompletenessW.svg',
    activeImagePath: '../../../../assets/images/DataCompletenessY.svg',
    url: 'cdm-metrics/point-of-care-data-quality',
  },
];

export const REPORTMENU: IMenuLink[] = [
  {
    name: 'translation.ReportSideNav.standardReport',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'report/standardreport',
  },
  {
    name: 'translation.ReportSideNav.customizedReport',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'report/customizedreport',
  },
];

export const RESOURCESMENU: IMenuLink[] = [
  {
    name: 'translation.ToolsAndSettingsSideNav.tabContrastPDF',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'resources/contrast-guide',
  },
  {
    name: 'translation.ToolsAndSettingsSideNav.tabHelpTour',
    imagePath: '../../../../assets/images/SummaryW.svg',
    activeImagePath: '../../../../assets/images/SummaryY.svg',
    url: 'service/metrics/summary/virtualcareactivity',
    event: HELP_TOUR_EVENT_NAME,
  },
];

export const CONTRASTTITLE: Map<string, string> = new Map([
  ['LostTimeDataType', 'Lost Time,/'],
  ['PocDataQualityDataType', 'Data Completeness,/'],
  ['ContrastWasteDataType', 'Unused Contrast,/'],
  ['RepeatInjectionDataType', 'Repeat Injections,/'],
  ['AtypicalTechAbortDataType', 'Technologist Abort,/'],
  ['IVInjectionDataType', 'Abort With Repeat,/'],
  ['AtypicalFlowRateLimitedDataType', 'Flow Rate Limiting,/'],
  ['SalineTestInject', 'Saline Test Inject,/'],
]);


export const SERVICE_APPLICATION_AREA = 'service';
export const OTHER_APPLICATION_AREA = 'other';
export const SELECTEDREPORT = 'CT';
export const SELECTEDREPORT_CTM = 'CT-M';

export const ROUTES_NAME = {
    cdmMetrics: '/cdm-metrics',
    metrics_repeatInjections: '/cdm-metrics/repeat-injections',
    metrics_ivissues: '/cdm-metrics/iv-issues',
    metrics_atypicalInjection: '/cdm-metrics/tech-abort',
    metrics_flowratelimited: '/cdm-metrics/flow-rate-limited',
    metrics_lostTime: '/cdm-metrics/lost-time',
    metrics_contrastWaste: '/cdm-metrics/contrast-waste',
    metrics_pointOfCareDataQuality: '/cdm-metrics/point-of-care-data-quality',
    metrics_salineTestInject: '/cdm-metrics/saline-test-inject',
    metrics_benchmark: '/cdm-metrics/benchmark_accessroute',
    protocol_permission: '/cdm-metrics/protocol-permission',
}