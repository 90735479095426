import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  getLanguage(): string {
    return JSON.parse(sessionStorage.getItem('lang') as string)
      ? JSON.parse(sessionStorage.getItem('lang') as string)
      : 'en';
  }
}
